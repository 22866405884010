import { Color } from '../../TSTypes/button';
import { DESIGN_SYSTEM_COLORS } from '../../constants';
import PrimaryColor from './primaryColor';

interface ColorScheme {
  color: string;
  backgroundColor: string;
  border: string;
}

const defaultCoolColor: ColorScheme = {
  color: DESIGN_SYSTEM_COLORS.grayscale['800'],
  backgroundColor: DESIGN_SYSTEM_COLORS.paywall.cool700,
  border: `1px solid ${DESIGN_SYSTEM_COLORS.paywall.cool700}`,
};

const defaultSpicyColors: ColorScheme = {
  color: DESIGN_SYSTEM_COLORS.grayscale['0'],
  backgroundColor: DESIGN_SYSTEM_COLORS.paywall.spicy700,
  border: `1px solid ${DESIGN_SYSTEM_COLORS.paywall.spicy700}`,
};

const defaultHotColors: ColorScheme = {
  color: DESIGN_SYSTEM_COLORS.grayscale['800'],
  backgroundColor: DESIGN_SYSTEM_COLORS.paywall.hot700,
  border: `1px solid ${DESIGN_SYSTEM_COLORS.paywall.hot700}`,
};

const defaultHotTrialColor: ColorScheme = {
  color: DESIGN_SYSTEM_COLORS.grayscale['0'],
  backgroundColor: DESIGN_SYSTEM_COLORS.green['400'],
  border: `1px solid ${DESIGN_SYSTEM_COLORS.green['400']}`,
};

const defaultVanillaColor: ColorScheme = {
  color: DESIGN_SYSTEM_COLORS.grayscale['800'],
  backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['350'],
  border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['350']}`,
};

const defaultDiscountColor: ColorScheme = {
  color: DESIGN_SYSTEM_COLORS.grayscale['0'],
  backgroundColor: DESIGN_SYSTEM_COLORS.green['500'],
  border: `1px solid ${DESIGN_SYSTEM_COLORS.green['500']}`,
};

function applyDefaultColors(paywall: Color, defaultColor: ColorScheme): Color {
  const planColor: Color = {
    outlined: {
      true: {
        ...paywall.outlined.true,
        state: {
          ...paywall.outlined.true.state,
          default: defaultColor,
        },
      },
      false: {
        ...paywall.outlined.false,
        state: {
          ...paywall.outlined.false.state,
          default: defaultColor,
        },
      },
    },
  };
  return planColor;
}

const hotPaywall: Color = applyDefaultColors(PrimaryColor, defaultHotColors);
const spicyPaywall: Color = applyDefaultColors(PrimaryColor, defaultSpicyColors);
const hotTrialPaywall: Color = applyDefaultColors(PrimaryColor, defaultHotTrialColor);
const coolTrialPaywall: Color = applyDefaultColors(PrimaryColor, defaultHotTrialColor);
const coolPaywall: Color = applyDefaultColors(PrimaryColor, defaultCoolColor);
const vanillaPaywall: Color = applyDefaultColors(PrimaryColor, defaultVanillaColor);
const discountPaywall: Color = applyDefaultColors(PrimaryColor, defaultDiscountColor);

export { hotPaywall, hotTrialPaywall, coolTrialPaywall, coolPaywall, vanillaPaywall, spicyPaywall, discountPaywall };
