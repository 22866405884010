import { Button } from '../../TSTypes/button';
import primary from './primaryColor';
import additional from './additionalColor';
import link from './linkColor';
import danger from './dangerColor';
import success from './successColor';
import textMatch from './textMatchColor';
import warning from './warningColor';
import modernLink from './modernLinkColor';
import {
  hotPaywall,
  hotTrialPaywall,
  coolPaywall,
  vanillaPaywall,
  spicyPaywall,
  coolTrialPaywall,
  discountPaywall,
} from './paywallColor';

const button: Button = {
  borderRadius: '0.25rem',
  height: '2.5rem',
  color: {
    primary,
    additional,
    link,
    success,
    danger,
    warning,
    textMatch,
    modernLink,
    hotPaywall,
    hotTrialPaywall,
    coolTrialPaywall,
    coolPaywall,
    vanillaPaywall,
    spicyPaywall,
    discountPaywall,
  },
  size: {
    small: {
      fontWeight: '600',
      fontSize: '0.875rem',
      padding: '0.3125rem 1.5rem',
      minWidth: '6.875rem',
    },
    large: {
      fontWeight: '500',
      fontSize: '1.125rem',
      padding: '0.3125rem 2rem',
      minWidth: '8.75rem',
    },
  },
};

export default button;
