import { useEffect, useState } from 'react';

const useNewFeature = (key = 'visitCount', maxVisits = 4) => {
  const [visitCount, setVisitCount] = useState(() => {
    const savedCount = localStorage.getItem(key);
    return savedCount ? parseInt(savedCount, 10) : 0;
  });

  useEffect(() => {
    const savedCount = localStorage.getItem(key);
    const currentCount = savedCount ? parseInt(savedCount, 10) : 0;

    if (currentCount < maxVisits) {
      const newCount = currentCount + 1;
      setVisitCount(newCount);
      localStorage.setItem(key, newCount.toString());
    }
  }, [key, maxVisits]);

  const isNewFeature = visitCount < maxVisits;

  const setFeatureIsOld = () => {
    localStorage.setItem(key, maxVisits.toString());
    setVisitCount(maxVisits);
  };

  return { visitCount, isNewFeature, setFeatureIsOld };
};

export default useNewFeature;
