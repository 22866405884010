import { MouseEventHandler } from 'react';

export const BUTTON_COLOR = {
  primary: 'primary',
  secondary: 'additional',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  textMatch: 'textMatch',
  link: 'modernLink',
  spicyPaywall: 'spicyPaywall',
  hotPaywall: 'hotPaywall',
  hotTrialPaywall: 'hotTrialPaywall',
  coolTrialPaywall: 'coolTrialPaywall',
  coolPaywall: 'coolPaywall',
  vanillaPaywall: 'vanillaPaywall',
  discountPaywall: 'discountPaywall',
};

export const VARIANTS = {
  solid: 'solid',
  secondary: 'secondary',
};

export type Variants = keyof typeof VARIANTS;

export type ButtonColor = keyof typeof BUTTON_COLOR;

export type ElementAlignment = 'start' | 'center' | 'end';

export type ChildElementConfig =
  | string
  | {
      name: string;
      justify: ElementAlignment;
    };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Used for QA testing purposes to identify an element
   */
  'data-qa-id'?: string;
  /**
   * event handler for the button when a user clicks it
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * The color of the button, these are "named" colors and come with their own intentions
   */
  color?: ButtonColor;
  /**
   * Should be used sparingly as most props will craft the button per the design documents
   */
  className?: string;
  /**
   * Allows you to make the button "outlined" via the secondary variant
   */
  variant?: Variants;
  /**
   * Disables the button
   */
  disabled?: boolean;
  /**
   * Some buttons require being small, this is as small as any interactive button should be
   */
  isSmall?: boolean;
  /**
   * Used when you are simply displaying only an icon inside the button
   * Currently this simply reduces the padding to 0
   */
  iconOnly?: boolean;
  /**
   * Sets the "min-width" of the button, used in some places in the designs
   */
  fixedWidth?: boolean;
  /**
   * Allows you to let the button word-wrap, typically we do not want this
   */
  wordWrap?: 'nowrap' | 'wrap';
  /**
   * The label for the button, this comes with some positioning options
   */
  label?: ChildElementConfig;
  /**
   * When you do not want to show a label but need one for accessibility purposes
   * When combined with the label prop, this will take priority for screen-readers
   */
  'aria-label'?: string;
  /**
   * The icon name or configuration for the "start" icon (typically left for LTR languages)
   */
  startIcon?: ChildElementConfig;
  /**
   * The icon name or configuration for the "end" icon (typically right for LTR languages)
   */
  endIcon?: ChildElementConfig;
  /**
   * Display the button as a block, mobile is default to block while desktop is inline
   */
  block?: boolean;
  /**
   * Display the button as a full width button, mobile is default to full width while desktop is auto
   */
  full?: boolean;
  /**
   * Props to provide for the button when the screen is between 769px and 1200px
   * Defaults are applied automatically, you're likely not required to change these in most situations
   */
  mdProps?: Pick<ButtonProps, 'block' | 'full'>;
  /**
   * Props to provide for the button when the screen is larger than 1200px
   * Defaults are applied automatically, you're likely not required to change these in most situations
   */
  lgProps?: Pick<ButtonProps, 'block' | 'full'>;
};
