import React, { FC, createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gqlAccountStatusEnum, gqlSystemRoleCode, gqlVerifyAccountInput } from '@minecraft.graphql-types';
import {
  ArtistSubscriptionFragment,
  AuthToken,
  ImpersonateAccountDoc,
  LanguageEnum,
  LoginDoc,
  SwitchAuthContextDoc,
  SystemRoleCode,
  UpdateAccountDoc,
  UpdateAccountInput,
  UpdatePasswordDoc,
  UserContextFragment,
  UserContextQueryDoc,
  VerifyAccountDoc,
  VerifyAccountEmailDoc,
} from '@minecraft.graphql-operations';
import { getEnvironment } from '@minecraft.environment';
import { TokenParsed, useDeviceAndBrowserInfo, LoginType, Validator } from '@minecraft.utils';
import { FEATURE_FLAGS, useFeatureExperiment, useFeatureExperimentUser } from '@blocs.features-experiments';
import { useTranslation, setLanguageToLocalStorage } from '@blocs.i18n';
import { getUnreadContentCardsCount } from '@blocs.braze';
import { addUserProperties, captureHeapEvent, HEAP_EVENT_NAMES } from '@blocs.heap';
import { captureExceptionEvent } from '../../../Sentry/Sentry';
import { refreshTokenApiCall, updatesCardCountVar, authTokensVar } from '../../apollo';
import { APPLICATIONS, ApplicationValue, QUERY_PARAMS, ACCOUNT_COUNTRY } from '../../constants';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  setOnboardingToken,
} from '../../utils/authStorage';
import { getErrorAccountStatus } from '../../utils/getClosedOrLockedErrorMessage';
import { useDefaultAppContext, shouldSwitchContext } from '../../hooks/useDefaultAppContext';
import {
  getAudience,
  getUrlForAudience,
  redirectToAudience,
  buildRedirectQueryParams,
  getAppValueForTokenApp,
} from '../../hooks/useRedirectByRole';
import redirectToLogin from '../../utils/redirectToLogin';
import redirectToRegister from '../../utils/redirectToRegister';
import { parseJwt, isTokenExpired } from '../../utils/token';
import { getRepsPriorityRole } from '../../utils/rolesPriority';
import { ApiError } from '../../types';
import { OnboardingToken, B21Token } from './graphqls';

const getValidApps = (
  roles: gqlSystemRoleCode[],
  parsedToken: TokenParsed,
  audience?: ApplicationValue
): { validApps: ApplicationValue[]; roleCode: gqlSystemRoleCode } => {
  const roleCodes = roles || [];
  const allApps = roleCodes.map((r) => getAudience(r));
  const appValueFromToken = getAppValueForTokenApp(parsedToken?.app);

  // users with REPS access can only log into the reps app
  // even if they have other roles
  if (allApps.includes(APPLICATIONS.reps)) {
    return { validApps: [APPLICATIONS.reps], roleCode: getRepsPriorityRole(roleCodes) };
  }

  // If the token contains an app value and that is within the all valid apps for this user
  // we need to return all cases of that app to determine if we should disambiguate between them
  // Note that this may look like ['casting', 'casting'] and that's normal for some situations (e.g. PC and Collab)
  // Basically that means they have multiple roles but the resulting application will be casting regardless
  if (allApps.includes(appValueFromToken)) {
    return { validApps: allApps, roleCode: parsedToken?.role?.code };
  }

  // if the audience query param is set, and it's a match for a valid app
  // then we will automatically redirect to that app
  if (audience && allApps.includes(audience)) {
    return { validApps: [audience], roleCode: roleCodes.find((r) => getAudience(r) === audience) };
  }

  // return the list of apps the user has access to (minus REPS b/c we already checked for that above)
  const allValidApps = [...new Set(allApps.filter((app) => Boolean(app) && app !== APPLICATIONS.reps))];

  return {
    validApps: allValidApps,
    roleCode: roleCodes.find((r) => getAudience(r) === allValidApps[0]),
  };
};

const getAppForToken = (token: TokenParsed): ApplicationValue => {
  switch (token?.role?.code) {
    case gqlSystemRoleCode.STUDIO:
    case gqlSystemRoleCode.CASTING_DIRECTOR:
    case gqlSystemRoleCode.PROJECT_CREATOR:
    case gqlSystemRoleCode.SHARED_PROJECT_USER:
      return APPLICATIONS.casting;
    case gqlSystemRoleCode.TALENT:
      return APPLICATIONS.talent;
    case gqlSystemRoleCode.AGENT:
    case gqlSystemRoleCode.MANAGER:
      return APPLICATIONS.reps;
    default:
      return null;
  }
};

const onboardingUrl = getEnvironment()?.ONBOARDING_URL;
const redirectToOnboardingApp = (token: string, email: string) => {
  setOnboardingToken(token);

  window.location.replace(`${onboardingUrl}/onboarding?username=${email}`);
};
// this throws an error that will be caught the same as
// a LOCKED or CLOSED account error from the BE
function InvalidAccountException(accountStatusCode: gqlAccountStatusEnum) {
  this.graphQLErrors = [
    {
      data: {
        name: 'userWithWrongStatus',
        errorCode: 21,
        errorData: {
          status: accountStatusCode,
        },
      },
    },
  ];
}

// custom exception for handling login alert: packages/ula/src/modules/auth/components/LoginComponents/LoginAlertMessage.tsx
function LegacyOnboardingDeprecatedException() {
  this.graphQLErrors = [
    {
      data: {
        name: 'legacyOnboardingDeprecated',
        errorCode: 30,
      },
    },
  ];
}

interface Tokens {
  access: string;
  accessParsed: TokenParsed;
  refresh: string;
  loginType: 'Onboarding' | 'B21';
  accountStatusCode: gqlAccountStatusEnum;
}

interface BaseMethodParams {
  redirectTo?: string;
}

interface SwitchAuthContextParams {
  role?: SystemRoleCode;
  organizationId?: number;
  artistId?: number;
}

interface AssumeCredentialsParams {
  accountId: number;
  roleCode?: gqlSystemRoleCode | SystemRoleCode;
}

interface VerifyTokensParams {
  redirectToTalentRegistration?: boolean;
}

interface UpdateAccountParams {
  accountId: number;
  input: UpdateAccountInput;
}

interface UpdatePasswordParams {
  oldPassword: string;
  newPassword: string;
}

interface VerifyEmailParams {
  email: string;
}

interface UserContextState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isUpdating: boolean;
  myAccount?: UserContextFragment;
  tokens?: Tokens;
  loginError?: ApiError;
  updateError?: ApiError;
  isLockedAcct?: boolean;
  isClosedAcct?: boolean;
  accountId?: number;
  artistId?: number;
  subscription?: ArtistSubscriptionFragment;
  previousSubscription?: ArtistSubscriptionFragment;
}

interface UserContextMethods {
  login: (params: gqlVerifyAccountInput) => Promise<void>;
  verifyTokens: (param: VerifyTokensParams) => Promise<void>;
  logout: (params: BaseMethodParams) => Promise<void>;
  switchAuthContext: (params: SwitchAuthContextParams) => Promise<UserContextState>;
  refreshTokens: () => Promise<UserContextState>;
  assumeCredentials: (params: AssumeCredentialsParams) => Promise<void>;
  updateAccount: (params: UpdateAccountParams) => Promise<UserContextState>;
  updatePassword: (params: UpdatePasswordParams) => Promise<void>;
  verifyEmail: (params: VerifyEmailParams) => Promise<void>;
}

type UserContext = UserContextState & UserContextMethods;

const buildDefaultContext = () => ({
  isAuthenticated: false,
  isAuthenticating: false,
  isUpdating: false,
  myAccount: null,
  permissions: null,
  tokens: null,
  loginError: null,
  updateError: null,
  isLockedAcct: false,
  isClosedAcct: false,
  accountId: null,
  artistId: null,
  subscription: null,
  previousSubscription: null,
});

const UserReactContext = createContext<UserContext>({
  ...buildDefaultContext(),
  login: () => {
    console.error('login not setup');

    return Promise.resolve();
  },
  verifyTokens: () => {
    console.error('verifyTokens not setup');

    return Promise.resolve();
  },
  refreshTokens: () => {
    console.error('refreshTokens not setup');

    return Promise.resolve({} as any);
  },
  logout: () => {
    console.error('logout not setup');

    return Promise.resolve();
  },
  switchAuthContext: () => {
    console.error('switchAuthContext not setup');

    return Promise.resolve({} as any);
  },
  assumeCredentials: () => {
    console.error('assumeCredentials not setup');

    return Promise.resolve();
  },
  updateAccount: () => {
    console.error('updateAccount not setup');

    return Promise.resolve({} as any);
  },
  updatePassword: () => {
    console.error('updatePassword not setup');

    return Promise.resolve();
  },
  verifyEmail: () => {
    console.error('verifyEmail not setup');

    return Promise.resolve();
  },
});

const getArtistSubscriptions = (myAccount?: UserContextFragment, artistId?: number) => {
  const artists = myAccount?.artists;
  const artist = artists?.find((a) => a.artistId === artistId);

  return {
    activeSubscription: artist?.activeSubscription ?? null,
    previousSubscription: artist?.previousSubscription ?? null,
  };
};

export const useUserContext = () => {
  const context = useContext(UserReactContext);

  if (!context) {
    throw new Error('UserContext is not initialized');
  }

  return context;
};

export const UserContextProvider: FC = ({ children }) => {
  const environment = getEnvironment();
  const currentApp = environment['AUDIENCE'] as ApplicationValue;
  const router = useHistory();
  const apollo = useApolloClient();
  const tokensVar = useReactiveVar(authTokensVar);
  const isLegacyOnboardingDeprecated = useFeatureExperiment(FEATURE_FLAGS.WEB_ONBOARDING_DEPRECATION);
  const { i18n } = useTranslation();
  const [userContext, setContext] = useState<UserContextState>(buildDefaultContext());
  const { identify } = useFeatureExperimentUser();
  const query = useMemo(() => new URLSearchParams(router.location.search), [router.location.search]);
  const queryRedirectAudience = query.get(QUERY_PARAMS.redirectAudience);
  const defaultAppContext = useDefaultAppContext();
  const setUserContext = useCallback(
    (newContext: Partial<UserContextState>) => {
      setContext((existingState) => {
        const updatedContext = { ...existingState, ...newContext };

        if (updatedContext?.myAccount?.country?.code !== existingState?.myAccount?.country?.code) {
          localStorage.setItem(ACCOUNT_COUNTRY, JSON.stringify(updatedContext?.myAccount?.country) ?? null);
        }

        return updatedContext;
      });
    },
    [setContext]
  );
  const { deviceInfo, browserInfo } = useDeviceAndBrowserInfo();

  useEffect(() => {
    // this updates the tokens on the context if there is a newer token in the reactive var
    // when they are automatically refreshed by the authErrorLink helper
    // so that if any permissions have changed they will be reflected by PermissionsProvider
    // which uses the UserContext.tokens value

    if (
      (tokensVar.access !== userContext?.tokens?.access || tokensVar.refresh !== userContext?.tokens?.refresh) &&
      tokensVar?.accessParsed?.iat > userContext?.tokens?.accessParsed?.iat
    ) {
      setUserContext({
        ...userContext,
        tokens: {
          ...userContext?.tokens,
          ...tokensVar,
        },
      });
    }
  }, [setUserContext, userContext, tokensVar]);

  const login = useCallback(
    async (input: gqlVerifyAccountInput) => {
      setUserContext({ ...userContext, isAuthenticating: true });
      try {
        let accessToken: string;
        let refreshToken: string;
        let loginType: 'B21' | 'Onboarding' = 'B21';
        if (isLegacyOnboardingDeprecated) {
          // Throw an error if user is logging in using legacy username (non-email)
          if (!Validator.Email.isValidEmail(input.name)) {
            throw new LegacyOnboardingDeprecatedException();
          }

          const loginResponse = await apollo.mutate({
            mutation: LoginDoc,
            variables: {
              email: input?.name,
              password: input?.password,
            },
            fetchPolicy: 'no-cache',
          });

          const authTokens: AuthToken = loginResponse?.data?.login;
          const { access, refresh } = authTokens;
          if (!access || !refresh) {
            throw new Error('Missing access or refresh token in login response');
          }
          accessToken = access;
          refreshToken = refresh;
        } else {
          const verifyAccountResponse = await apollo.query({
            query: VerifyAccountDoc,
            variables: {
              input,
            },
            fetchPolicy: 'no-cache',
          });

          const verifyAccount: OnboardingToken | B21Token = verifyAccountResponse?.data?.verifyAccount;

          if (!verifyAccount) {
            throw new Error('Unable to verify account');
          }

          // if a user's account has not been migrated from BAU then
          // loginType is returned as 'Onboarding',
          // in this case "token" is a jwt instead of an object
          // so we set it into localStorage to make it available in the
          // onboarding app when the user loads into it
          if (verifyAccount.loginType === 'Onboarding') {
            loginType = 'Onboarding';
            captureHeapEvent({
              name: HEAP_EVENT_NAMES.TALENT_LOGIN_NAV_SUCCESSFUL_LOGIN,
              data: {
                usernameEmail: input.name,
                loginMethod: LoginType.USERNAME,
                timestamp: new Date().toISOString(),
                device: deviceInfo,
                browser: browserInfo,
              },
            });
            // Closed or locked accounts should show an error to the user
            if ([gqlAccountStatusEnum.CLOSED, gqlAccountStatusEnum.LOCKED].includes(verifyAccount.accountStatusCode)) {
              throw new InvalidAccountException(verifyAccount.accountStatusCode);
            }

            redirectToOnboardingApp(verifyAccount.token, input?.name);
            return;
          }
          const { access, refresh } = verifyAccount.token;

          if (!access || !refresh) {
            throw new Error('Missing access or refresh token in verifyAccount response');
          }
          accessToken = access;
          refreshToken = refresh;
        }
        const parsedToken = parseJwt(accessToken);

        // throw an error for closed or locked accounts so that it will
        // display the correct message for the user
        // (they cannot complete login with a CLOSED or LOCKED account)
        if ([gqlAccountStatusEnum.CLOSED, gqlAccountStatusEnum.LOCKED].includes(parsedToken?.status)) {
          throw new InvalidAccountException(parsedToken?.status);
        }

        setAccessToken(accessToken);
        setRefreshToken(refreshToken);

        // identify user for feature flags & experiment context, heap tracking
        await identify(accessToken);

        captureHeapEvent({
          name: HEAP_EVENT_NAMES.TALENT_LOGIN_NAV_SUCCESSFUL_LOGIN,
          data: {
            usernameEmail: input.name,
            loginMethod: LoginType.EMAIL,
            timestamp: new Date().toISOString(),
            device: deviceInfo,
            browser: browserInfo,
          },
        });

        // fetch our account and permissions from the server b/c we don't trust
        // the data on the encoded token
        const userContextRes = await apollo.query({
          query: UserContextQueryDoc,
          fetchPolicy: 'no-cache',
          variables: { isTalent: currentApp === APPLICATIONS.talent },
        });

        const myAccount = userContextRes?.data?.myAccount;

        if (!myAccount) {
          throw new Error('Unable to fetch user context');
        }

        const accountStatusCode = myAccount?.accountStatus?.code as gqlAccountStatusEnum;
        const accountSystemRoleCodes = myAccount.accountSystemRoles?.map((r) => r?.code) as gqlSystemRoleCode[];

        // once we have valid tokens then we need to redirect the user
        // to the correct app
        const isPC = accountSystemRoleCodes?.includes(gqlSystemRoleCode.PROJECT_CREATOR);
        const isStudio = accountSystemRoleCodes?.includes(gqlSystemRoleCode.STUDIO);

        const userTheme = isStudio ? 'studio' : 'default';

        localStorage.setItem(QUERY_PARAMS.theme, userTheme);

        // get valid apps the user can visit (only valid for PC/CD & TALENT)
        const audience = queryRedirectAudience ? (String(queryRedirectAudience) as ApplicationValue) : undefined;
        const { validApps, roleCode } = getValidApps(accountSystemRoleCodes, parsedToken, audience);
        const redirectTo = buildRedirectQueryParams(
          query,
          myAccount?.organizations?.map((o) => o.id),
          false
        );

        const hasSingleValidApp = validApps.length === 1;

        if (hasSingleValidApp) {
          // setup google analytics tagging for the login event
          // if they have more than 1 validApp to login then
          // they will be redirected to the disambiguation page
          // and the login is tracked there instead to prevent duplication
          TagManager.dataLayer({
            dataLayer: {
              event: 'login',
              method: 'email',
              // eslint-disable-next-line camelcase
              user_id: myAccount?.id,
              // eslint-disable-next-line camelcase
              user_type: roleCode.toLowerCase(),
              locale: navigator.language,
              email: myAccount?.email,
            },
          });
        }

        // if the user's account is UNVERIFIED, then
        // they still need to verify their email before
        // they can be sent to a site
        if (accountStatusCode === gqlAccountStatusEnum.UNVERIFIED) {
          const queryParams = new URLSearchParams({
            email: myAccount?.email,
          });

          router.push(`/verify-email?${queryParams}`);

          return;
        }

        // PC users might not have completed the full
        // signup process and need to go to the final step page
        if (accountStatusCode === gqlAccountStatusEnum.INCOMPLETE && isPC) {
          router.push('/last-step');

          return;
        }

        // if the user has multiple valid apps to login to
        // send them to the disambiguation page
        if (!hasSingleValidApp) {
          router.push('/disambiguation');

          return;
        }

        redirectToAudience(validApps?.[0], redirectTo);

        const { activeSubscription, previousSubscription } = getArtistSubscriptions(myAccount, parsedToken?.artistId);

        // if the user has an active subscription with a trial, we want to track that in heap.
        if (activeSubscription) {
          if (activeSubscription?.trialLengthDays === 14) {
            addUserProperties({ subscription: '14day-premiumtrial' });
          } else if (activeSubscription?.trialLengthDays === 7) {
            addUserProperties({ subscription: '7day-premiumtrial' });
          }
        }

        // set user context for consumers
        setUserContext({
          ...userContext,
          isAuthenticated: true,
          isAuthenticating: false,
          tokens: {
            access: accessToken,
            refresh: refreshToken,
            accessParsed: parsedToken,
            loginType: isLegacyOnboardingDeprecated ? 'B21' : loginType,
            accountStatusCode,
          },
          // clean away any errors from previous attempts
          loginError: null,
          isLockedAcct: false,
          isClosedAcct: false,
          accountId: myAccount?.id,
          artistId: parsedToken?.artistId,
          subscription: activeSubscription,
          previousSubscription,
        });
      } catch (e) {
        console.error('login error', { e });
        captureHeapEvent({
          name: HEAP_EVENT_NAMES.TALENT_LOGIN_NAV_FAILED_LOGIN,
          data: {
            usernameEmail: input.name,
            loginMethod: Validator.Email.isValidEmail(input.name) ? LoginType.EMAIL : LoginType.USERNAME,
            timestamp: new Date().toISOString(),
            device: deviceInfo,
            browser: browserInfo,
          },
        });
        captureExceptionEvent(e, {
          location: 'UserContext - login',
          email: input.name,
        });
        // grab error code and / or account state for context
        const graphqlErr = e?.graphQLErrors?.[0];
        const accountStatusCode = getErrorAccountStatus(e);
        const errCode = e?.data?.errorCode || graphqlErr?.data?.errorCode;
        const isLockedAcct = accountStatusCode === gqlAccountStatusEnum.LOCKED;
        const isClosedAcct = accountStatusCode === gqlAccountStatusEnum.CLOSED;

        // errorCode 21 can be thrown if the account is LOCKED or CLOSED,
        // but can also be thrown if the user is partially through
        // the BAU onboarding process, so we have to check that they
        // are not in the LOCKED or CLOSED state to be sure we only
        // redirect them to onboarding when they actually need to finish
        if (errCode === 21 && !(isLockedAcct || isClosedAcct)) {
          redirectToOnboardingApp(graphqlErr?.data?.errorData?.token, input.name);
        }

        // set error and account state for context
        setUserContext({
          ...userContext,
          loginError: e,
          isAuthenticated: false,
          isAuthenticating: false,
          isLockedAcct,
          isClosedAcct,
        });
      }
    },
    [apollo, userContext, identify, router, query, queryRedirectAudience, setUserContext, currentApp]
  );

  const verifyTokens = useCallback(
    async ({ redirectToTalentRegistration = false }: VerifyTokensParams) => {
      try {
        // verifyTokens should only be called by AuthenticatedRoute
        // access & refresh tokens will already be set in localstorage by
        // RouteAuthenticator before sending the user to a route that
        // is wrapped by AuthenticatedRoute
        let shouldRedirect = false;

        // check if we have access & refresh token set
        let accessToken = getAccessToken();
        let refreshToken = getRefreshToken();

        // verify tokens are not expired
        const accessTokenExpired = isTokenExpired(accessToken);
        const refreshTokenExpired = isTokenExpired(refreshToken);

        if (!refreshToken || refreshTokenExpired) {
          shouldRedirect = true;
        }

        // get fresh access/refresh pair if access token expired
        if (accessTokenExpired && !shouldRedirect) {
          const refreshRes = await refreshTokenApiCall({ skipTokensVarUpdate: true }).catch((e) => {
            shouldRedirect = true;

            return e;
          });

          if (refreshRes?.data?.refreshToken?.access && refreshRes?.data?.refreshToken?.refresh) {
            // tokens are set in localstorage by refreshTokenApiCall
            // but we want to make sure we set the correct ones into state
            accessToken = refreshRes?.data?.refreshToken?.access;
            refreshToken = refreshRes?.data?.refreshToken?.refresh;
          } else {
            shouldRedirect = true;
          }
        }

        // check if token is valid (or we can switch into) app context
        let parsedToken = parseJwt(accessToken);
        const { validApps } = getValidApps(parsedToken?.roles, parsedToken, currentApp);

        if (!validApps.includes(currentApp)) {
          shouldRedirect = true;
        }

        // check if we are able to switch into this app context
        if (shouldSwitchContext({ defaultAppContext, token: parsedToken }) && !shouldRedirect) {
          // switch context to this app (or change to correct artist/org)
          try {
            const switchRes = await apollo.mutate({
              mutation: SwitchAuthContextDoc,
              variables: {
                refreshToken,
                role: defaultAppContext.roleCode as unknown as SystemRoleCode,
                artistId: defaultAppContext.artistId,
                organizationId: defaultAppContext.orgId,
              },
              fetchPolicy: 'no-cache',
            });

            if (switchRes?.data?.switchAuthContext?.access && switchRes?.data?.switchAuthContext?.refresh) {
              accessToken = switchRes?.data?.switchAuthContext?.access;
              refreshToken = switchRes?.data?.switchAuthContext?.refresh;
              parsedToken = parseJwt(accessToken);
            } else {
              throw switchRes;
            }
          } catch (e) {
            console.error('error switching context', { e });
            captureExceptionEvent(e, {
              location: 'UserContext - verifyTokens - switchContext',
              contextRole: defaultAppContext.roleCode,
              contextArtistId: defaultAppContext.artistId,
              constextOrganizationId: defaultAppContext.orgId,
              accountId: parsedToken?.id,
              tokenRole: parsedToken?.role?.code,
              tokenArtistId: parsedToken?.artistId,
              tokenOrganizationId: parsedToken?.organizationId,
            });
          }

          query.delete(QUERY_PARAMS.defaultAppContext);
          router.replace(`${router.location.pathname}?${query}`);
        }

        // check if we need to switch division context in reps app
        const tokenApp = getAppForToken(parsedToken);

        if (currentApp !== tokenApp && validApps.includes(tokenApp)) {
          window.location.replace(`/${tokenApp}`);
        }

        // redirect to login or registration if we're in the wrong place
        // or haven't found a valid context
        if (shouldRedirect) {
          if (redirectToTalentRegistration) {
            redirectToRegister(true);
          } else {
            redirectToLogin(true);
          }

          return;
        }

        // set tokens into localstorage so apollo can make authenticated calls
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        // identify user for heap, braze, feature flags & experiment context
        identify(accessToken);
        // update unread content cards count for "Updates" in main nav
        getUnreadContentCardsCount((unreadCount) => {
          updatesCardCountVar(unreadCount);
        }).catch((error) => console.warn('Failed to run Braze methods ', { error }));
        // update user context with myAccount
        const userContextRes = await apollo.query({
          query: UserContextQueryDoc,
          skip: shouldRedirect,
          fetchPolicy: 'no-cache',
          variables: { isTalent: currentApp === APPLICATIONS.talent },
        });

        if (userContextRes?.errors) {
          throw userContextRes?.errors?.[0];
        }

        const { activeSubscription, previousSubscription } = getArtistSubscriptions(
          userContextRes?.data?.myAccount,
          parsedToken?.artistId
        );

        // set user context for consumers
        setUserContext({
          ...userContext,
          isAuthenticated: true,
          isAuthenticating: false,
          myAccount: userContextRes?.data?.myAccount,
          tokens: {
            access: accessToken,
            accessParsed: parsedToken,
            refresh: refreshToken,
            loginType: 'B21',
            accountStatusCode: userContextRes?.data?.myAccount?.accountStatus?.code as gqlAccountStatusEnum,
          },
          // clean away any errors from previous attempts
          loginError: null,
          isLockedAcct: false,
          isClosedAcct: false,
          accountId: userContextRes?.data?.myAccount?.id,
          artistId: parsedToken.artistId,
          subscription: activeSubscription,
          previousSubscription,
        });
      } catch (e) {
        console.error('verify error', { e });
        captureExceptionEvent(e, {
          location: 'UserContext - verifyTokens',
        });
        // grab error code and / or account state for context
        redirectToLogin(false);
      }
    },
    [apollo, userContext, defaultAppContext, identify, query, router, setUserContext, currentApp]
  );

  const logout = useCallback(async ({ redirectTo }: BaseMethodParams) => {
    console.warn({ redirectTo });
    // not implemented yet

    return Promise.resolve();
  }, []);

  const switchAuthContext = useCallback(
    async ({ role, organizationId, artistId }: SwitchAuthContextParams) => {
      try {
        setUserContext({
          ...userContext,
          isUpdating: true,
        });

        // ensure the token has not expired, the refresh and retry doesnt work
        // exactly right when using the client directly because while the tokens
        // get updated, the refreshToken variable is already baked into the request context
        // and does not update correctly when it retries
        const accessToken = getAccessToken();
        const accessTokenExpired = isTokenExpired(accessToken);

        // get fresh access/refresh pair if access token expired
        if (accessTokenExpired) {
          await refreshTokenApiCall({ skipTokensVarUpdate: true });
        }

        // switch context to to a different organization or artist context
        const switchRes = await apollo.mutate({
          mutation: SwitchAuthContextDoc,
          variables: {
            refreshToken: getRefreshToken(),
            role,
            artistId,
            organizationId,
          },
          fetchPolicy: 'no-cache',
        });

        if (
          switchRes?.errors ||
          !switchRes?.data?.switchAuthContext?.access ||
          !switchRes?.data?.switchAuthContext?.refresh
        ) {
          throw switchRes?.errors?.[0] || new Error('failed to switch context');
        }

        const { access, refresh } = switchRes.data.switchAuthContext;

        // identify updates the feature / experiment user context if
        // anything is based on the secondary (orgId / artistId) key
        // also resets heap identity and braze user
        identify(access).catch((error) => console.warn('Failed to run Braze methods ', { error }));
        setAccessToken(access);
        setRefreshToken(refresh);

        const userContextRes = await apollo.query({
          query: UserContextQueryDoc,
          fetchPolicy: 'no-cache',
          variables: { isTalent: currentApp === APPLICATIONS.talent },
        });

        const parsedToken = parseJwt(access);

        const { activeSubscription, previousSubscription } = getArtistSubscriptions(
          userContextRes?.data?.myAccount,
          parsedToken?.artistId
        );

        const newContext = {
          ...userContext,
          isUpdating: false,
          myAccount: userContextRes?.data?.myAccount,
          tokens: {
            access,
            accessParsed: parsedToken,
            refresh,
            loginType: 'B21' as const,
            accountStatusCode: userContextRes?.data?.myAccount?.accountStatus?.code as gqlAccountStatusEnum,
          },
          accountId: userContextRes?.data?.myAccount?.id,
          artistId: parsedToken.artistId,
          subscription: activeSubscription,
          previousSubscription,
        };

        setUserContext(newContext);

        return newContext;
      } catch (e) {
        console.error('error switching context', { err: e });
        captureExceptionEvent(e, {
          location: 'UserContext - switchAuthContext',
          role,
          organizationId,
          artistId,
        });
        const newContext = {
          ...userContext,
          isUpdating: false,
          updateError: e,
        };

        setUserContext(newContext);

        return newContext;
      }
    },
    [apollo, identify, userContext, setUserContext, currentApp]
  );

  const refreshTokens = useCallback(async () => {
    try {
      setUserContext({
        ...userContext,
        isUpdating: true,
      });

      // switch context to to a different organization or artist context
      const refreshRes = await refreshTokenApiCall({ skipTokensVarUpdate: true });

      if (refreshRes?.errors || !refreshRes?.data?.refreshToken?.access || !refreshRes?.data?.refreshToken?.refresh) {
        throw refreshRes?.errors?.[0] || new Error('failed to switch context');
      }

      const { access, refresh } = refreshRes.data.refreshToken;

      // identify updates the feature / experiment user context if
      // anything is based on the secondary (orgId / artistId) key
      // also resets heap identity and braze user
      identify(access).catch((error) => console.warn('Failed to run Braze methods ', { error }));
      setAccessToken(access);
      setRefreshToken(refresh);

      const userContextRes = await apollo.query({
        query: UserContextQueryDoc,
        fetchPolicy: 'no-cache',
        variables: { isTalent: currentApp === APPLICATIONS.talent },
      });

      const parsedToken = parseJwt(access);

      const { activeSubscription, previousSubscription } = getArtistSubscriptions(
        userContextRes?.data?.myAccount,
        parsedToken?.artistId
      );

      const newContext = {
        ...userContext,
        isUpdating: false,
        myAccount: userContextRes?.data?.myAccount,
        tokens: {
          access,
          accessParsed: parsedToken,
          refresh,
          loginType: 'B21' as const,
          accountStatusCode: userContextRes?.data?.myAccount?.accountStatus?.code as gqlAccountStatusEnum,
        },

        accountId: userContextRes?.data?.myAccount?.id,
        artistId: parsedToken.artistId,
        subscription: activeSubscription,
        previousSubscription,
      };

      setUserContext(newContext);

      return newContext;
    } catch (e) {
      console.error('error refreshing tokens', { err: e });
      captureExceptionEvent(e, {
        location: 'UserContext - refreshTokens',
      });
      const newContext = {
        ...userContext,
        isUpdating: false,
        updateError: e,
      };

      setUserContext(newContext);

      return newContext;
    }
  }, [apollo, identify, userContext, setUserContext, currentApp]);

  const assumeCredentials = useCallback(
    async ({ accountId, roleCode }: AssumeCredentialsParams) => {
      try {
        const typedCode = roleCode as gqlSystemRoleCode;
        const { data } = await apollo.mutate({
          mutation: ImpersonateAccountDoc,
          variables: { accountId },
          fetchPolicy: 'no-cache',
        });

        const acctAccessToken = data?.loginImpersonatedAccount?.access;
        let acctRefreshToken = data?.loginImpersonatedAccount?.refresh;
        const parsed = parseJwt(acctAccessToken);

        if (!parsed) {
          throw new Error(`could not get a token for account ${accountId}`);
        }

        const acctSystemRoles = parsed?.roles;
        let redirectRole = parsed?.role?.code;

        if (!acctSystemRoles.includes(typedCode) || !redirectRole) {
          throw new Error(`role ${roleCode} is not a valid option for account with id ${accountId}`);
        }

        if (roleCode !== redirectRole) {
          const { data: switchContextData } = await apollo.mutate({
            mutation: SwitchAuthContextDoc,
            variables: { refreshToken: acctRefreshToken, role: roleCode as SystemRoleCode },
            fetchPolicy: 'no-cache',
          });

          acctRefreshToken = switchContextData.switchAuthContext.refresh;
          redirectRole = typedCode;
        }

        if (!redirectRole) {
          throw new Error(`could not find a valid role on account ${accountId} for role ${roleCode}`);
        }

        const audienceUrl = getUrlForAudience(getAudience(redirectRole));

        if (!audienceUrl) {
          throw new Error(`role ${redirectRole} does not have an application to redirect to`);
        }

        window.open(`${audienceUrl}/authenticate/${acctRefreshToken}`);
      } catch (e) {
        console.error(e);
      }
    },
    [apollo]
  );

  const updateAccount = useCallback(
    async ({ accountId, input = {} }: UpdateAccountParams): Promise<UserContextState> => {
      try {
        setUserContext({
          ...userContext,
          isUpdating: true,
        });

        const { data } = await apollo.mutate({
          mutation: UpdateAccountDoc,
          variables: { accountId, input, isTalent: currentApp === APPLICATIONS.talent },
          fetchPolicy: 'no-cache',
        });

        if (input.languageLocaleId) {
          const locale = data.updateAccount.language;
          const code = locale ? locale.code : LanguageEnum.English;

          let languageKey = '';

          switch (code) {
            case LanguageEnum.Spanish:
              languageKey = 'es';
              break;
            case 'KLINGON':
              languageKey = 'klingon';
              break;
            default:
              languageKey = 'en';
          }

          // update i18n language to match settings
          // then cache language onto localstorage
          // for subsequent logins in unauthenticated context
          i18n.changeLanguage(languageKey);
          window.document.documentElement.lang = languageKey;
          setLanguageToLocalStorage(languageKey);
        }

        const newCtx = { ...userContext, myAccount: data?.updateAccount, isUpdating: false };
        setUserContext(newCtx);

        return newCtx;
      } catch (e) {
        console.error(e);
        const newCtx = {
          ...userContext,
          isUpdating: false,
          updateError: e,
        };
        setUserContext(newCtx);

        return newCtx;
      }
    },
    [apollo, userContext, i18n, setUserContext, currentApp]
  );

  const updatePassword = useCallback(
    async ({ oldPassword, newPassword }: UpdatePasswordParams) => {
      if (!oldPassword || !newPassword) {
        return;
      }

      try {
        setUserContext({
          ...userContext,
          isUpdating: true,
        });
        await apollo.mutate({
          mutation: UpdatePasswordDoc,
          variables: { oldPassword, newPassword },
          fetchPolicy: 'no-cache',
        });

        setUserContext({
          ...userContext,
          isUpdating: false,
        });
      } catch (e) {
        console.error(e);

        setUserContext({
          ...userContext,
          isUpdating: false,
          updateError: e,
        });
      }
    },
    [apollo, userContext, setUserContext]
  );

  const verifyEmail = useCallback(
    async ({ email }: VerifyEmailParams) => {
      if (!email) {
        return;
      }

      try {
        setUserContext({
          ...userContext,
          isUpdating: true,
        });
        const verifyEmailRes = await apollo.mutate({
          mutation: VerifyAccountEmailDoc,
          fetchPolicy: 'no-cache',
        });

        const accessToken = verifyEmailRes?.data?.verifyAccountEmail?.access;
        const refreshToken = verifyEmailRes?.data?.verifyAccountEmail?.refresh;

        setRefreshToken(refreshToken);
        setAccessToken(accessToken);
        identify(accessToken);

        // update user context with myAccount
        const userContextRes = await apollo.query({
          query: UserContextQueryDoc,
          fetchPolicy: 'no-cache',
          variables: { isTalent: currentApp === APPLICATIONS.talent },
        });

        // set user context for consumers
        setUserContext({
          ...userContext,
          isUpdating: false,
          myAccount: userContextRes?.data?.myAccount,
          tokens: {
            access: accessToken,
            accessParsed: parseJwt(accessToken),
            refresh: refreshToken,
            loginType: 'B21',
            accountStatusCode: userContextRes?.data?.myAccount?.accountStatus?.code as gqlAccountStatusEnum,
          },
        });
      } catch (e) {
        console.error(e);

        setUserContext({
          ...userContext,
          isUpdating: false,
          updateError: e,
        });
      }
    },
    [apollo, userContext, identify, setUserContext, currentApp]
  );

  const fullContext = useMemo(() => {
    return {
      ...userContext,
      login,
      verifyTokens,
      logout,
      refreshTokens,
      switchAuthContext,
      assumeCredentials,
      updateAccount,
      updatePassword,
      verifyEmail,
    };
  }, [
    userContext,
    login,
    verifyTokens,
    logout,
    switchAuthContext,
    refreshTokens,
    assumeCredentials,
    updateAccount,
    updatePassword,
    verifyEmail,
  ]);

  return <UserReactContext.Provider value={fullContext}>{children}</UserReactContext.Provider>;
};
