import { GraphQLError } from 'graphql';
import { AppKey, TranslateFn } from './types';

interface GraphQLErrorCN extends GraphQLError {
  data?: {
    errorCode: string;
  };
}

const ErrorMessages: Record<number, string> = {
  1: 'internalServiceError',
  2: 'invalidSessionError',
  3: 'missingSessionTokenError',
  4: 'failedLoginAttemptError',
  5: 'authorizationError',
  6: 'dataNotFoundError',
  7: 'emailAlreadyInUseError',
  8: 'invalidPasswordResetTokenError',
  9: 'accountNotFoundError',
  10: 'validationError',
  11: 'queryError',
  12: 'itemNotCreatedError',
  13: 'invalidOldPasswordError',
  14: 'missingRequestParameterError',
  15: 'itemNotUpdatedError',
  16: 'itemNotDeletedError',
  17: 'itemAlreadyExistsError',
  18: 'invalidEmailResetTokenError',
  19: 'accountSystemRolesNotFound',
  20: 'unprocessableEntity',
  21: 'userWithWrongStatus',
  22: 'accountNotFoundOnInstance',
  23: 'invalidToken',
  24: 'emailTokenExpired',
  25: 'accountClaim',
  26: 'authTokenNoLongerValid',
  27: 'accountLocked',
  28: 'multipleInstances',
  29: 'accountOrgClosed',
  504: 'internalError',
  1001: 'authUserWithDiffStatus',
  1002: 'authInvalidLinkToken',
  1003: 'emailExists',
  1004: 'noAccountFoundInstance',
  1005: 'authUserNotFound',
  1006: 'authInvalidToken',
  1007: 'authEmailTokenExp',
  1008: 'authAccountClaim',
  1009: 'authAccountActive',
  1010: 'authAccountVerifyWithDiffStatus',
  23505: 'dbErrorUnique',
};

// ErrorType is actually readonly it's type of new Error()
export type ErrorDataType = {
  graphQLErrors: readonly GraphQLErrorCN[];
};

const getErrorMessage = (error: ErrorDataType): string => {
  const errorObject = error?.graphQLErrors?.[0] || error;
  // changes after API error object change - now we need to handle errorCode from exeption if it exists
  const code = (errorObject as any)?.extensions?.exception?.errorCode || (errorObject as any)?.data?.errorCode;

  return ErrorMessages[code] || '';
};

export const getIntlErrorMessage = (
  t: TranslateFn,
  error: ErrorDataType,
  appKey: AppKey = AppKey.COMMON,
  prefix?: string,
  errorNamesToSkip?: Array<string>
): string => {
  if (!error) {
    // When the error is undefined/null, we should return the generic error message(reused from Google SSO error copy)
    // This is a temporary solution until we have a better error handling strategy from the backend.
    return t('login:login.error.googleAuthenticationFailed.generic');
  }

  // get error key from error status
  const errorKey = getErrorMessage(error);

  // here are the errors that should be skipped
  if (errorNamesToSkip && errorNamesToSkip.find((errName) => errName === errorKey)) {
    return '';
  }

  //  try to grab a translation specific to the app and prefix provided
  let localKey = prefix ? `${prefix}.error.${errorKey}` : `error.${errorKey}`;
  let errorMessage = t(`${appKey}:${localKey}`);

  // if the translate fn returns the key, then there isn't anything matched, try for a generic error message
  if (localKey === errorMessage) {
    localKey = `globalError.${errorKey}`;
    errorMessage = t(`${AppKey.COMMON}:${localKey}`);
  }

  // if there is no localisation for specific error just return generic translation "Something went wrong"
  if (localKey === errorMessage) {
    errorMessage = t('common:globalError.generic');
  }

  return errorMessage;
};

export const I18N_LANG = 'i18nextLng';

export const getLanguageFromLocalStorage = (): string => {
  return localStorage.getItem(I18N_LANG);
};

export const setLanguageToLocalStorage = (lng: string): void => {
  localStorage.setItem(I18N_LANG, lng);
};
